import React, {useContext} from 'react';
import styled, {ThemeContext} from 'styled-components';
import {ArrowCta} from '@hoffman/shared/src/components/Cta/cta';

interface NotFoundPageProps {
  headline: string;
  copy: string;
  cta: string;
  link: string;
}

export const NotFoundPageSection = (props: NotFoundPageProps): JSX.Element => {
  const theme = useContext(ThemeContext);
  return (
    <ResponsiveContainer>
      <Container>
        <StyledHeadline>{props.headline}</StyledHeadline>
        <ArrowCta
          color="#FFF8EF"
          link={props.link}
          text={props.text}
          size={`${theme.fontSizes[4]}px`}
        />
        <ArrowCta
          color="#FFF8EF"
          link={props.link2}
          text={props.text2}
          size={`${theme.fontSizes[4]}px`}
        />
      </Container>
    </ResponsiveContainer>
  );
};

NotFoundPageSection.defaultProps = {
  headline: 'Headline',
  copy: 'Lorem ipsum',
  cta: 'Learn more',
  link: 'https://google.com',
  cta2: 'Learn more',
  link2: 'https://google.com',
};

export default NotFoundPageSection;

const ResponsiveContainer = styled.div`
  color: ${({theme}) => theme.colors.text};
`;

const Container = styled.div`
  margin: 0px;
  max-width: 500px;
  display: grid;
  grid-gap: 25px;
  font-weight: ${({theme}) => theme.fontWeights.light};
  div {
    line-height: 48px;
    margin-bottom: 30px;

    /* @media (max-width: ${({theme}) => theme.breakpoints[1]}) {
      font-size: 40px;
    } */
  }

  a {
    letter-spacing: 4px;
    padding: 15px 30px;
    max-width: 250px;
    text-align: center;
  }
`;

const StyledHeadline = styled.div`
  font-family: ${({theme}) => theme.fontFaces.heading};
  font-size: ${({theme}) => theme.fontSizes[5]}px;
  letter-spacing: -0.24px;
`;
