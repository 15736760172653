import React from 'react';
import styled from 'styled-components';
import Headline from '@hoffman/shared/src/components/TextFeatures/headline';
import NotFoundPageSection from '@hoffman/shared/src/components/page-sections/NotFoundPageSection';
import BaseLayout from '@hoffman/shared/src/components/BaseLayout';
import SEO from '../../../../packages/docs/src/components/seo';
import NavbarBlock from '~blocks/NavbarBlock';
import FooterBlock from '~blocks/FooterBlock';
import Section from '@hoffman/shared/src/components/Section';
import SectionPadding from '@hoffman/shared/src/components/SectionPadding';
import SectionBackgroundGroup from '@hoffman/shared/src/components/SectionBackgroundGroup';

const NotFoundPage = () => (
  <BaseLayout>
    <SEO title={'Page Not Found'} description={'Page Not Found'} />
    <NavbarBlock />
    <SectionBackgroundGroup altBg={true}>
      <SectionPadding py="shorter">
        <StyledContainer>
          <Headline text="Error 404" size="40px" />
        </StyledContainer>
      </SectionPadding>
    </SectionBackgroundGroup>
    <Section themeName="DARK">
      <SectionBackgroundGroup>
        <SectionPadding>
          <NotFoundPageSection
            headline="The page you’re looking for couldn’t be found. Please return to the Amaris homepage and try again. "
            link="/"
            text="Go Back Home"
            link2="/contact"
            text2="Contact Us"
          />
        </SectionPadding>
      </SectionBackgroundGroup>
    </Section>
    <FooterBlock />
  </BaseLayout>
);

export default NotFoundPage;

const StyledContainer = styled.div`
  margin-top: ${({theme}) => theme.spaces[9]}px;
`;
